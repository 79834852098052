<!-- 联盟领导人介绍 -->
<template>
  <div class="AllianceLeader">
    <div v-if="this.language === 'zh_CN'">
      <el-carousel trigger="click" height="700px">
        <el-carousel-item >
          <div class="cn-div">
            <h1>联盟主席</h1>
            <div class="leader">
              <div>
                <el-image
                    class="cn-left-image"
                    :src="require('@/assets/images/aboutus/tu1.png')"
                    fit="contain" />
              </div>
              <div class="cn-right-info">
                <h1>&nbsp;韩金明 先生</h1>
                <p>&nbsp;&nbsp;<span>⬤</span> 世界大健康经济发展联盟主席</p>
                <p> &nbsp;&nbsp;<span>⬤</span>  Fohow （Tianjin）Pharmaceutical Co.,Ltd. 董事长</p>
                <p> &nbsp;&nbsp;<span>⬤</span>  FOHERB GROUP 董事长</p>
                <p> &nbsp;&nbsp;<span>⬤</span>  中国国际投资促进会常务理事</p>
                <p> &nbsp;&nbsp;<span>⬤</span>  中国中药协会慢病防治药物研究专业委员会副主任</p>
              </div>
            </div>
            <div class="cn-footer-info">
              <p>&emsp;&emsp;世界大健康经济发展联盟，英文简称: WHIEDA，是一家全球性的大健康产业联盟机构。总部位于中国。
                <br>
                &emsp;&emsp;世界大健康经济发展联盟在'一带一路"互联互通的大背景下成立，以a弘扬养生文化，造福人类健康'为宗旨，以全球视野集聚高端资源，以全球智库贼能产业升级，
                以全球平台惠泽人类健康，致力于为全球大健康产业优秀企业、行业组织、科研机构、业界专家学者搭建交流与合作平台，在遵守各国法律尊重各国文化的基础上，
                加强以大健康产业为主、其他产业为辅的国际间的产学研合作对接，促进健康产业国际资本投资与流通，发掘区域间大健康市场潜力，加快健康养生理念的广泛传播，
                推动*全球健康革命”，引领世界大健康产业可持续发展，为提高全人类的健康水平而不俾努力。
                <br>
                &emsp;&emsp;世界大健康经济发展联盟由联盟主席团领导(主席一名、执行主席一名、副主席若干名），下设秘书处、产业经济发展委员会、健康养生教育委员会、产业专家顾问委员会、
                产业金融服务委员会等专业机构，并设立欧亚大健康经济发展联盟、非洲大健康经济发展联盟、美洲及大洋洲大健康经济发展联盟等区域性分支机构。
              </p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item >
          <div class="cn-div">
            <h1>联盟执行主席</h1>
            <div class="leader">
              <div>
                <el-image
                    class="cn-left-image"
                    :src="require('@/assets/images/aboutus/tu2.png')"
                    fit="contain" />
              </div>
              <div class="cn-right-info">
                <h1>&nbsp;曲峻生 先生</h1>
                <p> &nbsp;&nbsp;<span>⬤</span>  世界大健康经济发展联盟执行主席</p>
                <p> &nbsp;&nbsp;<span>⬤</span>  Fohow （Tianjin）Pharmaceutical Co.,Ltd. 总裁</p>
                <p> &nbsp;&nbsp;<span>⬤</span>  FOHERB GROUP 总裁</p>
                <p> &nbsp;&nbsp;<span>⬤</span>  和治友德科技发展有限公司 董事长</p>
                <p> &nbsp;&nbsp;<span>⬤</span>  中国商业经济学会健康产业委员会常务副会长</p>
              </div>
            </div>
            <div class="cn-footer-info">
              <p>&emsp;&emsp;他是一位杰出的大健康行业管理专家，创业导师，具有丰富的全球化企业运营管理经验的领导人，为世界大健康经济发展联盟的有序发展保驾护航。</p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item >
          <div class="cn-div">
            <h1>欧亚大健康经济发展联盟主席</h1>
            <div class="leader">
              <div>
                <el-image
                    class="cn-left-image"
                    :src="require('@/assets/images/aboutus/tu3.png')"
                    fit="contain" />
              </div>
              <div class="cn-right-info">
                <h1>刘 挺 先生</h1>
                <p> &nbsp;<span>⬤</span>  欧亚大健康经济发展联盟主席</p>
                <p> &nbsp;<span>⬤</span>  FOHERB GROUP  副总裁</p>
                <p> &nbsp;<span>⬤</span>  FOHERB GROUP 董事长</p>
                <p> &nbsp;<span>⬤</span>  优先健康公司董事长</p>
                <p> &nbsp;<span>⬤</span>  具有丰富的跨国公司及多元文化管理经验</p>
              </div>
            </div>
            <div class="cn-footer-info">
              <p>&emsp;&emsp;他是一位杰出的拥有丰富的跨国公司及多元文化管理经验的领导人、深耕欧亚市场二十余年的营销模式建设专家。</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="this.language !== 'zh_CN'">
      <el-carousel trigger="click" height="660px">
        <el-carousel-item>
          <div class="carousel-div">
            <h1>联盟主席</h1>
            <div class="leader">
              <div>
                <el-image
                    class="left-image"
                    :src="require('@/assets/images/aboutus/tu1.png')"
                    fit="contain" />
              </div>
              <div class="right-info">
                <h1>&nbsp;г-н Хан Цзиньмин</h1>
                <p>&nbsp;&nbsp;<span>⬤</span>&nbsp;Председатель Международной Ассоциации «Здоровье и Процветание»</p>
                <p>&nbsp;&nbsp;<span>⬤</span>&nbsp;Председатель Совета  директоров  Тяньцзиньской фармацевтической компании Fohow (Tianjin) Pharmaceutical Co.,Ltd. </p>
                <p>&nbsp;&nbsp;<span>⬤</span>&nbsp;Председатель Совета директоров корпорации FOHERB</p>
                <p>&nbsp;&nbsp;<span>⬤</span>&nbsp;Исполнительный директор Китайской Ассоциации  по содействию реализации международных инвестиций</p>
                <p>&nbsp;&nbsp;<span>⬤</span>&nbsp;Заместитель директора Исследовательского комитета по изучению лекарственных препаратов для профилактики и лечения хронических заболеваний при Ассоциации китайской традиционной медицины</p>
              </div>
            </div>
            <div class="footer-div">
              <p>&emsp;&emsp;Главный тренд 21 века – это здоровый образ жизни. Каждый человек стремится к здоровой и качественной жизни. Как международная организация  Ассоциация WHIEDA уделяет особое внимание поиску  высококачественной продукции и расширяет возможности промышленной модернизации, тем самым способствуя созданию единой глобальной площадки для построения здорового общества.</p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item >
          <div class="carousel-div">
            <h1>联盟执行主席</h1>
            <div class="leader">
              <div>
                <el-image
                    class="left-image"
                    :src="require('@/assets/images/aboutus/tu2.png')"
                    fit="contain" />
              </div>
              <div class="right-info">
                <h1>&nbsp;г-н Цюй Цзюньшэн</h1>
                <p>&nbsp;&nbsp;<span>⬤</span>Испольнительный председатель Международной Ассоциации «Здоровье и Процветание»</p>
                <p>&nbsp;<span>⬤</span>&nbsp;Президент Fohow （Tianjin）Pharmaceutical Co.,Ltd.</p>
                <p>&nbsp;<span>⬤</span>&nbsp;Президент корпорации FOHERB </p>
                <p>&nbsp;<span>⬤</span>&nbsp;Председатель Совета директоров Fohow Technology Development Co., Ltd.</p>
                <p>&nbsp;&nbsp;<span>⬤</span>&nbsp;Исполнительный вице-президент Китайского научного сообщества по изучению бизнеса и экономики</p>
              </div>
            </div>
            <div class="footer-div">
              <p>&emsp;&emsp;Главный тренд 21 века – это здоровый образ жизни. Каждый человек стремится к здоровой и качественной жизни. Как международная организация  Ассоциация WHIEDA уделяет особое внимание поиску  высококачественной продукции и расширяет возможности промышленной модернизации, тем самым способствуя созданию единой глобальной площадки для построения здорового общества.</p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item >
          <div class="carousel-div">
            <h1>欧亚大健康经济发展联盟主席</h1>
            <div class="leader">
              <div>
                <el-image
                    class="left-image"
                    :src="require('@/assets/images/aboutus/tu3.png')"
                    fit="contain" />
              </div>
              <div class="right-info">
                <h1>г-н Лю Тин</h1>
                <p> &nbsp;<span>⬤</span>  Председатель Евразийской Ассоциации «Здоровье и Процветание»</p>
                <p> &nbsp;<span>⬤</span>  Вице-президент корпорации FOHERB</p>
                <p> &nbsp;<span>⬤</span>  FOHERB GROUP 董事长</p>
                <p> &nbsp;<span>⬤</span>  Председатель Совета директоров «Приоритет-Здоровье»</p>
                <p> &nbsp;<span>⬤</span>  Многолетний опыт работы в международных компаниях и мультикультурном управлении.</p>
              </div>
            </div>
            <div class="footer-div">
              <p>&emsp;&emsp;Евразийская Ассоциация «Здоровье и Процветание» разработала специальную международную бизнес-платформу для сотрудничества между ведущими предприятиями и различными организациями в сфере здравоохранения. Также Ассоциация стремится содействовать развитию индустрии здоровья в странах Евроазиатского региона, предоставляя своим клиентам по всему миру только высококачественную продукцию и услуги.</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "AllianceLeader",
  data() {
    return {
      IntroduceStep: 1
    }
  },
  computed:{
    ...mapGetters([
      'language'
    ])
  }
}
</script>

<style scoped lang="scss">
  .cn-div {
    width: 100%;
  }
  .cn-left-image {
    width: 33rem;
    height: 19rem;
  }
  .cn-right-info {
    text-align: left;
  }
  .cn-footer-info {
    padding: 1rem 1rem 0 2rem;
    text-align: left
  }
  .leader {
    display: flex;
    justify-content: space-around;
    padding: 1rem 1rem 0 1rem;
    .left-image {
      flex: 2;
      width: 20rem;
      height: 19rem;
    }
    .right-info {
      flex: 3;
      text-align: left;
      line-height: 1.5rem;
    }
  }
  .el-carousel__item {
    background-color: #f5f7fa;
  }
  .footer-div {
    padding: 1rem 1rem 0 2rem;
    text-align: left;
  }
  @media screen and (max-width: 991px){
    .leader{
      display: block;
      .left-image{
        width: 100%;
        height: 150px;
        float: left;
      }
    }
    .carousel-div {
      width: 100%;
      height: 660px;
      overflow: scroll;
    }
    .footer-div {
      padding-bottom: 50px;
    }
    .cn-div {
      height: 700px;
      overflow: scroll;
    }
    .cn-left-image {
      width: 100%;
      height: 150px;
    }
    .cn-footer-info {
      text-align: left;
      padding: 0 1rem 1rem 1rem;
    }


  }
</style>
