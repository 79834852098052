import { render, staticRenderFns } from "./alliance-leader.vue?vue&type=template&id=915ea2aa&scoped=true"
import script from "./alliance-leader.vue?vue&type=script&lang=js"
export * from "./alliance-leader.vue?vue&type=script&lang=js"
import style0 from "./alliance-leader.vue?vue&type=style&index=0&id=915ea2aa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "915ea2aa",
  null
  
)

export default component.exports